<template>
  <h2>Page not found</h2>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>

</style>
